
import { Vue, Component } from 'vue-property-decorator';
import ConChecker
  from '@/ui/components/components/ConnectivityChecker.vue';

/**
 * Component that shows Notification settings tab
 */
@Component({
  components: {
    ConChecker,
  },
})
export default class Notification extends Vue {
}
